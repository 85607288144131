import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output, signal } from '@angular/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Component({
  selector: 'publish-confirmation',
  standalone: true,
  imports: [MatProgressSpinner],
  templateUrl: './publish-confirmation.component.html',
  styleUrl: './publish-confirmation.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PublishConfirmationComponent implements OnInit {
  message = signal("All done! You can now click logout button.");

  @Output() readonly showHeaderAndNavigation = new EventEmitter<boolean>();
  @Output() readonly showLogoutButton = new EventEmitter<boolean>();

  ngOnInit(): void {
    this.showHeaderAndNavigation.emit(false);
    this.showLogoutButton.emit(true);
  }
}
