<div class="section">
    <div class="titleContainer">
        <div class="subText">
            <p class="labelWrapper">
                <span class="label">
                    {{message()}}
                </span>
            </p>
        </div>
    </div>
</div>
